import classNames from 'classnames';
import { ImageDisplay } from '../../../image-display';
import { BulkActionsCheckbox } from '../../../bulk-actions/bulk-actions-checkbox';
import { BulkActionsHookData } from '../../../bulk-actions/types';
import { useBulkActionsContext } from '../../../bulk-actions/context';
import {
    extractQuantityFromListingSummary,
    getItemIdFromListingSummary,
    getTransactionIdFromListingSummary,
    getVariationIdFromListingSummary
} from '../../../bulk-actions/utils/utils';
import { ItemPriceAndQuantity } from '../item-price-and-quantity';
import { useQuickViewContext } from '../../../quick-view/context';
import { QuickViewAction } from '../../../quick-view';
import { LineItemSummaryProps } from './types';
import './styles.less';

export function LineItemSummary({
    bulkActionSelection,
    disabled,
    hasBulkActions = false,
    listingSummary,
    lineItemId = '',
    moduleName,
    onAction,
    firstLineItem,
    isInvoice = false,
    seller
}: LineItemSummaryProps) {

    const {
        activeBulkActionsModule,
    }: BulkActionsHookData = useBulkActionsContext();
    const { shouldShowQuickViewAction } = useQuickViewContext();

    if (!listingSummary) {
        return null;
    }

    const itemId = getItemIdFromListingSummary(listingSummary);
    const listingSummaryQuantity = extractQuantityFromListingSummary(listingSummary);
    const transactionId = getTransactionIdFromListingSummary(listingSummary);
    const variationId = getVariationIdFromListingSummary(listingSummary);
    const shouldHaveLinkOpenQuickView = !disabled && shouldShowQuickViewAction();

    return (
        <div className='listsummary-content'>
            <div className="listsummary-container page-grid">
                { hasBulkActions && activeBulkActionsModule && activeBulkActionsModule === moduleName && !isInvoice && (
                    <div
                        className="grid-item-checkbox"
                    >
                        <BulkActionsCheckbox
                            bulkActionSelection={bulkActionSelection}
                            itemDisabled={disabled}
                            itemId={itemId}
                            labelId={listingSummary?.listingId}
                            lineItemId={lineItemId}
                            listingSummaryQuantity={listingSummaryQuantity}
                            transactionId={transactionId}
                            variationId={variationId}
                            firstLineItem={firstLineItem}
                            seller={seller}
                        />
                    </div>
                )}
                <div className={
                    classNames(
                        'grid-item-image',
                        {
                            'grid-item-image-active-bulk-actions': activeBulkActionsModule && hasBulkActions,
                        }
                    )
                }>
                    <div className="listsummary-content__image-ctr img-ctr-square">
                        { listingSummary?.image && (
                            <>
                                { shouldHaveLinkOpenQuickView ? (
                                    <QuickViewAction listingId={itemId}>
                                        <ImageDisplay
                                            aria-hidden="true"
                                            title={listingSummary.image.title}
                                            url={listingSummary.image.URL}
                                        />
                                    </QuickViewAction>
                                ) : (
                                    <ImageDisplay
                                        aria-hidden="true"
                                        title={listingSummary.image.title}
                                        url={listingSummary.image.URL}
                                    />
                                ) }
                            </>
                        )}
                    </div>
                </div>
                <ItemPriceAndQuantity
                    hasBulkActions={hasBulkActions}
                    listingSummary={listingSummary}
                    onAction={onAction}
                    moduleName={moduleName}
                    lineItemId={lineItemId}
                />
            </div>
        </div>
    );
}
