import { AppContext, CartClientState, SignInPageUrlObject } from '../../../../common/utils/hydration';
import { getScreenFlowDestinationUrl } from './specifications';

export function resetGlobalHeaderCartValue(cartState?: CartClientState) {
    if (!cartState) {
        return;
    }

    // In case window.GH.resetCart doesn't exist, retry on page load
    if (typeof window.GH?.resetCart !== 'function') {
        window.addEventListener('load', () => {
            resetGlobalHeaderCartValue(cartState);
        });
        return;
    }

    const activeQuantity = cartState?.meta?.activeQuantity;

    if (activeQuantity !== undefined && activeQuantity >= 0) {
        window.GH.resetCart(activeQuantity);
    }

}

export function redirectIfRequired(cartState: CartClientState, appContext?: AppContext, signInPageUrlObj?: SignInPageUrlObject): boolean {
    const screenFlowDestination = cartState?.meta?.screenFlowDestination;
    const signInUrlBase = signInPageUrlObj?.signInPageUrlBase;
    const fullSignInPageUrl = signInPageUrlObj?.signInPageUrl;
    const windowLocation = window.location.href;

    const url = getScreenFlowDestinationUrl(
        screenFlowDestination,
        signInUrlBase,
        fullSignInPageUrl,
        windowLocation,
        appContext
    );
    if (url) {
        window.location.assign(url);
        return true;
    }

    const path = window.location.pathname;
    let pageURL = window.location.origin;
    if (path.indexOf('/cart') !== -1) {
        pageURL += '/cart';
    }

    history.replaceState({}, '', pageURL);
    return false;
}
