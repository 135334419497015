import classNames from 'classnames';
import { ItemSpecificMessages } from '@ebay/payments-item-specific-messages-react-expsvc';
import { EbayTrackingClick } from '@ebay/ebayui-app-react/ebay-tracking';
import { EbaySignal } from '@ebay/ui-core-react/ebay-signal';
import { getSignalByIdAndType, getSignalLocalizedText, signalsTypes, MappedSignals } from '@ebay/signals-components-provider/utils';
import '@ebay/skin/signal';
import { useBulkActionsContext } from '../../../bulk-actions/context';
import * as listingSummarySpecs from '../line-item-summary/utils';
import { TEST_IDS } from '../../../../utils/test-ids';
import { TextDisplay } from '../../../text-display';
import { useLineItemContext } from '../../context';
import { useAppMetaContextData } from '../../../../utils/app-meta-context/hooks';
import { getActionTrackingData } from '../../../../utils/tracking-utils';
import { getUrl } from '../../../../utils/experience-service-types/action/action';
import { HotnessSignal } from '../../../../../../common/utils/hydration';
import { ItemBadge } from '../item-badge';
import { useMappedSignalsContext } from '../../../../utils/signals/hooks/signals-context-hook';
import { useI18n } from '../../../../../common/utils/i18n';
import { useQuickViewContext } from '../../../quick-view/context';
import { QuickViewAction } from '../../../quick-view';
import { useSignalsContext } from '../../../signals/context';
import { ItemSignals } from '../../../signals/item-signals';
import { hasEmojis } from './utils';
import type { ItemDetailsProps } from './types';
import './styles.less';

function buildSignaLStatusForItem(hotnessSignal: HotnessSignal | undefined) {
    if (hotnessSignal) {
        switch (hotnessSignal.level) {
            case 'TRUSTWORTHY':
                return 'trustworthy';
            case 'RECENT':
                return 'recent';
            case 'TIME_SENSITIVE':
                return 'time-sensitive';
            case 'NEUTRAL':
                return 'neutral';
        }
    }
    return 'neutral';
}

export function ItemDetails({ listingSummary, lineItemId, hasBulkActions, moduleName }: ItemDetailsProps) {
    const { itemSpecificTopLevelModules, deviceMode } = useLineItemContext();
    const { activeBulkActionsModule, cartBulkXoEnabled } = useBulkActionsContext();
    const { appMeta, meta } = useAppMetaContextData();
    const {
        getSignalsLocales
    } = useI18n();
    const { shouldShowQuickViewAction } = useQuickViewContext();
    const { mappedSignals = {} } = useMappedSignalsContext();
    const signalsBundleText = getSignalsLocales();
    const { isInFGQLSignalsTreatment } = useSignalsContext();

    const listingId = listingSummary?.listingId;
    const variationId = listingSummary?.variationId;
    let itemIdAndVariationId = listingId;
    if (listingId && variationId) {
        itemIdAndVariationId = `${listingId}-${variationId}`;
    }

    const mappedHotnessSignal = getSignalByIdAndType(listingId as string, signalsTypes.HOTNESS, mappedSignals as MappedSignals);
    const mappedProgramBadge = getSignalByIdAndType(itemIdAndVariationId as string, signalsTypes.PROGRAM_BADGE, mappedSignals as MappedSignals);
    const HotnessSignalComponent = mappedHotnessSignal?.Component || null;
    // NOTE: it is expected that the localized text returnd by getSignalLocalizedText is undefined,
    // this util is only used to get the localized text for the signals that cannot be localized from backend
    const hotnessSignalLocalizedText = getSignalLocalizedText(mappedHotnessSignal?.signalName, signalsBundleText);
    const ProgramBadgeComponent = mappedProgramBadge?.Component || null;

    if (!listingSummary) {
        return null;
    }

    const titleHasEmojis = hasEmojis(listingSummary.title);

    const listingSummaryTitle = listingSummarySpecs.getTitle(listingSummary);
    const isTitleDisabled = activeBulkActionsModule !== null && !cartBulkXoEnabled;
    const title = listingSummaryTitle?.textSpans?.[0]?.text || '';

    const actionName = listingSummaryTitle?.action?.name;
    const refActionName = moduleName === 'SAVE_FOR_LATER' && actionName ? `SFL_${actionName}` : actionName;
    const itemLinkTrackingObject = getActionTrackingData({
        action: listingSummaryTitle?.action,
        isGuest: appMeta?.isGuest,
        itemId: listingId,
        refActionName: refActionName,
        shoppingCartId: meta?.shoppingCartId
    });

    const shouldHaveLinkOpenQuickView = !isTitleDisabled && shouldShowQuickViewAction();
    const itemLink = shouldHaveLinkOpenQuickView ? (
        <QuickViewAction
            data-test-id={TEST_IDS.cartItemLink}
            listingId={listingId}
        >
            {title}
        </QuickViewAction>
    ) : (
        <EbayTrackingClick trackingList={itemLinkTrackingObject}>
            <a
                href={getUrl(listingSummaryTitle?.action)}
                target={appMeta?.isMobile ? '_self' : '_blank'}
                aria-disabled={isTitleDisabled}
                data-test-id={TEST_IDS.cartItemLink}
                data-track={JSON.stringify(itemLinkTrackingObject)}
                className={classNames({ 'link--disabled': isTitleDisabled })}
                rel="noreferrer"
            >
                {title}
            </a>
        </EbayTrackingClick>
    );

    return (
        <div className={
            classNames('grid-item-information', {
                'grid-item-information--active-bulk-actions': hasBulkActions && activeBulkActionsModule
            })}
        >
            {listingSummarySpecs.hasTitle(listingSummary) && (
                <div className='grid-item-title'>
                    {(listingSummarySpecs.hasHotnessSignal(listingSummary)) && !HotnessSignalComponent && (
                        <EbaySignal status={buildSignaLStatusForItem(listingSummary.hotnessSignal)}>
                            {listingSummary.hotnessSignal && listingSummary.hotnessSignal?.text}
                        </EbaySignal>
                    )}
                    {
                        HotnessSignalComponent && (
                            <HotnessSignalComponent
                                localizedText={
                                    hotnessSignalLocalizedText
                                }
                            />
                        )
                    }
                    <h3
                        className={classNames('item-title text-truncate-multiline black-link', {
                            'text-truncate-multiline-with-emoji': titleHasEmojis,
                            'lines-2': !titleHasEmojis
                        }
                        )}
                    >
                        { itemLink }
                    </h3>
                </div>
            )}

            {listingSummarySpecs.hasCondition(listingSummary) && (
                <div className="item-condition">
                    <TextDisplay data={listingSummarySpecs.getCondition(listingSummary)} />
                </div>
            )}

            {!ProgramBadgeComponent && !isInFGQLSignalsTreatment && listingSummarySpecs.getBadges(listingSummary)?.map((itemBadge, index) =>
                <ItemBadge key={index} title={itemBadge?.title} />
            )}

            { isInFGQLSignalsTreatment && (
                <ItemSignals listingId={listingId} variationId={variationId} />
            )}

            <div className="grid-item-details">
                {listingSummarySpecs.hasItemSpecificMessages(listingSummary) && (
                    <ItemSpecificMessages
                        additionalInfoModuleData={itemSpecificTopLevelModules}
                        data={listingSummarySpecs.getItemSpecificMessages(listingSummary)}
                        deviceMode={deviceMode}
                    />
                )}

                {listingSummarySpecs.hasItemVariations(listingSummary) && (
                    <div className="item-variations">
                        {listingSummarySpecs.getItemVariations(listingSummary)?.map((itemVariation, index) => (
                            <div key={index}>
                                <TextDisplay data={itemVariation} />
                            </div>
                        ))}
                    </div>
                )}

                {listingSummarySpecs.hasEEKRating(listingSummary) && (
                    <div className="item-eek">
                        <TextDisplay data={listingSummarySpecs.getEEKRating(listingSummary)} />
                    </div>
                )}

                {listingSummarySpecs.hasHotness(listingSummary) && !HotnessSignalComponent && (
                    <div className="item-hotness IMPORTANT">
                        <TextDisplay data={listingSummarySpecs.getHotness(listingSummary)} />
                    </div>
                )}

            </div>
        </div>
    );
}
