import { useContext } from 'react';
import { ClientLocales } from '../../../../common/utils/hydration';
import { I18nContext } from './context';
import { I18nHook } from './types';

export function useI18n(): I18nHook {
    const i18n = useContext<ClientLocales>(I18nContext);

    return {
        getCartPageTitle() {
            return i18n?.cartPageTitle || '';
        },
        getGenericErrorMessage() {
            return i18n?.genericErrorMessage || '';
        },
        getSignInChooserCloseDialog() {
            return i18n?.signInChooserCloseDialog || '';
        },
        getSignInChooserContinueAsGuest() {
            return i18n?.signInChooserContinueAsGuest || '';
        },
        getSignInChooserDialogAriaLabel() {
            return i18n?.signInChooserDialogAriaLabel || '';
        },
        getSignInChooserContinueToSignIn() {
            return i18n?.signInChooserContinueToSignIn || '';
        },
        getSpinnerAriaLabel() {
            return i18n?.spinnerAriaLabel || 'Loading';
        },
        getBulkActionsTourtipContent() {
            return i18n?.bulkActionsTourtipContent || '';
        },
        getBulkActionsTourtipA11YClose() {
            return i18n?.bulkActionsTourtipA11YClose || '';
        },
        getBulkCheckoutTourTipContent() {
            return i18n?.bulkCheckoutTourTipContent || '';
        },
        getSignalsLocales() {
            return i18n?.signalsLocales || {};
        },
        getEmptyCartWithWatchlist() {
            return i18n?.emptyCartWithWatchlist || {};
        }
    };
}
