import { Adjustment } from '../../../../../../common/utils/hydration';

const RETURN_URL_REGEX = /ru={redirect_uri}/;

function replaceReturnUrl(url: string): string {
    const ru = window.location.href;

    if (RETURN_URL_REGEX.test(url)) {
        return url.replace(RETURN_URL_REGEX, `ru=${ru}`);
    }

    return url;
}

export function goToAdjustmentUrl(adjustment: Adjustment): void {
    if (!adjustment?.action?.URL) {
        return;
    }

    const adjustmentUrl = replaceReturnUrl(adjustment.action.URL);
    window.location.assign(adjustmentUrl);
}
