import { CartClientState } from '../../../../common/utils/hydration';
import { AjaxError, AjaxCallParameters, AjaxContextType, AjaxCallMaker } from './types';

export function makeAjaxCall<RequestData>({ url, data, srt, timeout, logging, reloadOnFailure = true, httpRequestObject }: AjaxCallParameters<RequestData>): Promise<CartClientState> {
    return new Promise((resolve, reject) => {
        const ajaxRequest = httpRequestObject ? httpRequestObject : new XMLHttpRequest();
        const method = data ? 'POST' : 'GET';
        let href = window.location.href;
        // removing last '/' character from href
        if (href && href.length > 1 && href.charAt(href.length - 1) === '/') {
            href = href.substring(0, href.length - 1);
        }
        ajaxRequest.open(method, href + url, true);
        ajaxRequest.setRequestHeader('Content-type', 'application/json');
        const postData = data && {
            ...data,
            srt
        };

        // Don't need any of the below operations for a clientside logging call
        if (!logging) {
            ajaxRequest.timeout = timeout || 12000;
            ajaxRequest.onload = (event) => {
                const response = processResponse(event, reloadOnFailure);
                if (response) {
                    resolve(response);
                }
            };
            ajaxRequest.onerror = () => handleAjaxFailure(reloadOnFailure);
            ajaxRequest.ontimeout = handleAjaxTimeout;
        }

        let postDataString = '';
        try {
            postDataString = JSON.stringify(postData);
        } catch (error: any) {
            return reject(error);
        }

        ajaxRequest.send(postDataString);
    });
}

function handleAjaxFailure(reloadOnFailure = true) {
    if (reloadOnFailure) {
        window.location.reload();
    }
}

/**
 * Reload page on Ajax timeout (passed from config)
 */
function handleAjaxTimeout() {
    window.location.reload();
}

function passInfoForRedirect(url: string | URL) {
    // Track button user just clicked to trigger ajax call
    const clickedEl = document.activeElement;

    // For c2sfl, we need to pass through the lineItemId so we know which item to sfl after redirect
    if (clickedEl && clickedEl.getAttribute('data-line-item-id')) {
        let urlWithParams = url;
        const lineItemId = clickedEl.getAttribute('data-line-item-id');

        if (lineItemId) {
            // Return URL (ru) will now look something like:
            // https://cart.ebay.com/api/c2s?lineItemId=220009825466
            urlWithParams += `?lineItemId=${lineItemId}`;
        }

        return urlWithParams;
    }
    return url;
}
/**
 * Process Ajax response
 */
function processResponse(event: ProgressEvent, reloadOnFailure = true): CartClientState | void {
    const request = event?.target as XMLHttpRequest;
    if (!request) {
        return;
    }
    if (request.status >= 200 && request.status < 400) {
        try {
            return JSON.parse(request.responseText) as CartClientState;
        } catch (e) {
            handleAjaxFailure(reloadOnFailure);
        }
    } else if (request.status === 401 || request.status === 403) { // Redirect to sign-in URL attached to err obj
        let err: AjaxError = {};
        try {
            err = JSON.parse(request.responseText) as AjaxError;
        } catch (e) {
            handleAjaxFailure(reloadOnFailure);
            return;
        }
        // Sign-in redirect flow
        let signInPageUrl: AjaxError['signInPageUrl'] = err.signInPageUrl;
        if (signInPageUrl) {
            signInPageUrl = passInfoForRedirect(signInPageUrl);
            window.location.assign(signInPageUrl);
        }
        // PPA account upgrade flow
        const upgradeUrl = err.upgradeUrl;
        if (upgradeUrl) {
            window.location.assign(upgradeUrl);
        }
    } else {
        handleAjaxFailure(reloadOnFailure);
    }
}

/**
 * Creates an AjaxCallMaker function to be used outside of React context, like logging on browser-init
 * For React context we use the useAjax() hook instead.
 */
export function createPlainAjaxCallMaker<RequestData>(ajaxContext?: AjaxContextType): AjaxCallMaker<RequestData> {
    if (!ajaxContext) {
        throw new Error('[createMakeAjaxCall()]: ajaxContext is required');
    }

    return (url: string, data?: RequestData, logging?: boolean) => {
        return makeAjaxCall({
            url,
            data,
            srt: ajaxContext.srt,
            timeout: ajaxContext.timeout,
            logging
        });
    };
}