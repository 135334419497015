import { CartDetails, ListingIdToHeroDataMap } from '../../../../../common/utils/hydration';
import { AjaxCallMaker } from '../../../utils/ajax';
import { ListingHeroDataRequest } from '../types';

export async function getListingHeroData(makeAjaxCall: AjaxCallMaker<ListingHeroDataRequest>, listingIds: string[]): Promise<ListingIdToHeroDataMap | undefined> {
    try {
        const data = await makeAjaxCall('/api/hero', { listingIds }, undefined, false);
        return data?.modules?.listingHeroData;
    } catch (e) {
        // We are doing nothing here as errors here should be logged on backend, so duplicate logging
        // isn't required.
        return undefined;
    }
}

export function getListingIdsFromCartDetails(cartDetails: CartDetails | undefined, maxViexpioCallsPerRequest = 10) {
    const listingIds: string[] = [];
    for (const sellerBucket of cartDetails?.sellerBuckets || []) {
        for (const lineItem of sellerBucket?.lineItems || []) {
            for (const listingSummary of lineItem?.listingSummaries || []) {
                if (listingIds.length === maxViexpioCallsPerRequest) {
                    return listingIds;
                } else if (listingSummary?.listingId) {
                    listingIds.push(listingSummary.listingId);
                }
            }
        }
    }
    return listingIds;
}
