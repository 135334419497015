// The dynamic import causes issues with testing, so instead I am going to mock it!
function startLazyLoadingQuickView() {
    // TypeScript throws an error here, but this file is compiled by webpack, so it's fine.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    import('../');
}

export {
    startLazyLoadingQuickView
};
