import {
    Action,
    ActionType,
    BannerCard,
    BubbleHelp,
    CallToAction,
    FieldAction,
    Icon,
    IconAndText,
    Image,
    ListingSummary,
    Message,
    Module,
    SectionModule,
    TextualDisplay,
    TextualDisplayValue,
    TextualEntry,
    TextualSelection,
    TitledModule,
    Validation
} from '@ebay/experience-types-ebay';
import { AdListing, HtmlModule } from '@ebay/nuts-js-client/src/server';
import { FeatureFlagExperimentResponseObject } from '@ebay/experiments';
import { GetSignalsFGQLResponse } from '@ebay/payments-graph-client/signals';
import { Modify } from '../custom-utility-types';
import { QtagsProcessedData } from '../experimentation/types';

declare global {
    interface Window {
        __APP_INITIAL_STATE__?: CartClientState;
        PUBLIC_PATH?: string;
    }
}

export interface ServerSideExperiments {
    name: string;
    value: string;
}

export interface AppConfig {
    ajaxTimeout: number;
    dWebBannerReposition?: boolean;
    jqueryUrl: string;
    reactEnableAds: boolean;
    reactEnablePreloadHtml?: boolean;
    shoppingCartBulkActionsTourTip?: boolean;
    xoPrefetchEnabled: boolean;
    xoPrefetchURL: string;
    enableGuestOTPAuthFlow?: boolean;
    bulkActionsServiceCallTimeout: number;
    enableQuickView: boolean;
    maxViexpioCallsPerRequest: number;
    ebayPremiumServiceLocales: string[];
}

export interface AppSiteConfig {
    quantumMetrics?: QuantumMetrics;
}

export enum FocusOnActionActionNames {
    MoveToSFL = 'SAVE_FOR_LATER',
    MoveToCart = 'ADD_BACK_TO_CART',
    RemoveItem = 'REMOVE_ITEM',
    RemoveItemFromSFL = 'REMOVE_ITEM_FROM_CART',
    RemoveItemFromCart = 'REMOVE_ITEM_FROM_SFL',
}

export enum FGQLActions {
    GetSignals = 'GET_SIGNALS'
}

export interface AppMeta {
    pageId?: string;
    isGuest?: boolean;
    isMobile?: boolean;
    previousAction?: FocusOnActionActionNames | null;
    baseUrl?: string;
    enableGuestOTPAuthFlow?: boolean;
}

export interface AppContext {
    enableGuestOTPAuthFlow?: boolean;
    locale?: string;
    isMobile?: boolean;
}

export interface AppMetaContextData {
    appConfig?: AppConfig;
    appMeta?: AppMeta;
    meta?: Meta;
}

export interface SignInPageUrlObject {
    baseUrl?: string;
    signInPageUrlBase?: string;
    signInPageUrl?: string;
}

export interface ScreenFlowDestinationSiteSpeedParam {
    carttime?: string;
}

export interface ScreenFlowDestination {
    name?: string;
    params?: Record<string, string>;
    URL?: string;
    siteSpeedParam?: ScreenFlowDestinationSiteSpeedParam;
}

export interface Shopper {
    address?: Record<string, string>;
    isGuestUser?: boolean;
    userId?: string;
}

export interface Seller {
    sellerIdentifier?: number;
    sellerLabel?: TextualDisplay;
    sellerName?: TextualDisplay;
    userId?: string;
    userLoginName?: string;
}


export interface Meta {
    pageTitle?: string;
    screenFlowDestination?: ScreenFlowDestination;
    continueShoppingUrl?: string;
    signInPageUrlObj?: SignInPageUrlObject;
    activeQuantity?: number;
    onloadSpinner?: boolean;
    shoppingCartId?: string;
    locale?: string;
    shopper?: Shopper;
    experiments?: ServerSideExperiments[];
}

export interface CartCallToAction {
    action?: Action;
    accessibilityText?: string;
    disabled?: boolean;
    text?: string;
    type?: 'PRIMARY' | 'SECONDARY';
}

export interface EmptyCartActionsMap {
    START_SHOPPING?: CartCallToAction;
    SIGN_IN?: CartCallToAction;
}

export interface EmptyCart {
    emptyCartMessage?: TextualDisplay;
    subMessages?: TextualDisplay[];
    actionMap?: EmptyCartActionsMap;
}

export interface InfoBubble extends BubbleHelp {
    accessibilitySeverity?: string;
    accessibilityTextCollapse?: string;
    accessibilityTextExpand?: string;
}

export interface TextualDisplayWithInfoBubble extends TextualDisplay {
    infoBubble?: InfoBubble;
}

export type LogisticsDetails = TextualDisplay[];
export type LogisticsCosts = TextualDisplay[];
export type ItemAdditionalPrices = TextualDisplay[];

export interface Logistics {
    logisticsDetails?: LogisticsDetails;
}

export type ModifiedTextualDisplayValue = Modify<TextualDisplayValue, {
    value?: string;
}>;

type AdditionalActionTypes = 'USE_TEXTUAL_ENTRY';
export type ModifiedTextualSelection = Modify<TextualSelection, {
    originalValue?: string;
    paramKey?: string;
    paramValue?: string;
    action?: {
        name: ActionType | AdditionalActionTypes;
    };
    fieldActions?: FieldAction[];
    label?: TextualDisplay;
}>;

export type ParamValueTypeForTextbox = string | number;
export type ModifiedValidationForTextbox = Modify<Validation, {
    params?: { [key: string]: any };
    validationType?: MoreThanOrEqualTo | LessThanOrEqualTo;
}>;

export type ModifiedTextualEntry = Modify<TextualEntry, {
    paramKey?: string;
    paramValueType?: ParamValueTypeForTextbox;
    paramValue?: ParamValueTypeForTextbox;
    originalValue?: ParamValueTypeForTextbox;
    validations?: ModifiedValidationForTextbox[];
    validationMessages?: Message[];
    label?: TextualDisplay;
}>;

type MoreThanOrEqualTo = 'MORE_THAN_OR_EQUAL_TO';
type LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO';


export interface SelectionFormType {
    group?: ModifiedTextualSelection[];
    heading?: TextualDisplay;
    label?: TextualDisplay;
    selected?: ModifiedTextualDisplayValue;
    paramKeyValues?: Record<string, string>;
}

export interface EntryFormType {
    group?: ModifiedTextualEntry[];
    heading?: TextualDisplay;
    actions?: Record<string, TextualDisplay>;
}

export interface QuantityForm {
    entryForm?: EntryFormType;
    selectionForm?: SelectionFormType;
}

export enum BrandingType {
    EbayFastAndFree = 'EBAY_FAST_AND_FREE',
    EbayPlusApplied = 'EBAY_PLUS_APPLIED',
    FastAndFreeGuaranteed = 'FNF_GUARANTEED'
}

export interface Branding {
    brandingType?: BrandingType;
    message?: TextualDisplay;
}

interface ShoppingCartListingSummaryCustomProps {
    disabled?: boolean;
    logistics?: Logistics;
    logisticsCosts?: LogisticsCosts;
    additionalPrices?: ItemAdditionalPrices;
    condition?: TextualDisplay;
    quantityForm?: QuantityForm;
    brandingMessages?: Branding[];
    statusMessages?: TextualDisplay[];
    actions?: Record<string, TextualDisplay>;
    transactionId?: string;
    discountMessage?: TextualDisplay;
    priceChangePercentage?: TextualDisplay;
}

export interface HotnessSignal {
    accessibilityText?: string;
    level?: string;
    text?: string;
}

export interface ShoppingCartListingSummary extends ListingSummary {
    additionalInfo?: IconAndText[];
    itemBadges?: ItemBadges[];
    itemSpecificMessages?: Message[];
    __sc?: ShoppingCartListingSummaryCustomProps;
    hotnessSignal?: HotnessSignal;
}
export interface ItemBadges {
    title?: IconAndText;
}
export interface Adjustment {
    action?: Action;
    applied?: boolean;
    title?: TextualDisplay;
    message?: TextualDisplay[];
    amount?: TextualDisplay;
    icon?: Icon;
}

export interface LineItem {
    bulkActionSelection?: TextualSelection;
    lineItemId?: string;
    listingSummaries?: ShoppingCartListingSummary[];
    adjustments?: Adjustment[];
    notifications?: Notification[];
    actions?: Record<string, TextualDisplay>;
}

export interface SellerBucketActionMenu {
    actions?: Record<string, TextualDisplay | TextualDisplayWithInfoBubble>;
    menuTitle?: TextualDisplay;
}

export interface SellerBucket {
    lineItems?: LineItem[];
    seller?: SellerInformation;
    actionMenu?: SellerBucketActionMenu;
    disabled?: boolean;
    adjustments?: Adjustment[];
}

export interface SellerInformation {
    logo?: Image;
    positiveFeedback?: TextualDisplay;
    sellerLabel?: TextualDisplay;
    sellerName?: TextualDisplay;
    sellerIcons?: Icon[];
}

export interface BulkActionsData {
    _type: string;
    bulkModeActions: CallToAction[];
    disableBulkMode: TextualDisplay;
    enableBulkMode: TextualDisplay;
    selectAll: TextualSelection;
}

export interface EventProperty {
    moduledtl?: string;
    ebc?: string;
    pull_to_refresh?: string;
    pageci?: number;
    imp?: number; // will be logged as "cp" in the final event
    pge?: number; // will be logged as "p" in the final event
    sid?: string | null;
    actionKind?: string;
    sellerId?: string;
    itemReferenceId?: string;
    itemId?: string;
}

export interface TrackingData {
    eventFamily?: string;
    eventAction?: string;
    actionKind?: string;
    actionKinds?: string[];
    operationId?: string;
    flushImmediately?: boolean;
    eventProperty?: EventProperty;
    clientImpressionPageId?: string;
}

export interface CartTitle {
    _type?: string;
    title: TextualDisplay;
}

export interface CartDetailsMeta {
    name?: string;
    trackingList?: TrackingData[];
}

export interface CartDetails {
    meta?: CartDetailsMeta;
    moduleTitle?: string;
    bulkActions?: BulkActionsData;
    emptyCart?: EmptyCart;
    sellerBuckets?: SellerBucket[];
}

export interface CartSummaryItem {
    type?: string;
    label?: TextualDisplayWithInfoBubble;
    value?: TextualDisplayWithInfoBubble;
    secondaryValue?: TextualDisplay;
}

export enum CallToActionNames {
    ContinueShopping = 'CONTINUE_SHOPPING',
    CheckoutCart = 'CHECK_OUT_CART',
    RedirectToCheckout = 'REDIRECT_TO_CHECKOUT'
}

export enum CallToActionDisabledMessages {
    SelectItem = 'SELECT_ITEM'
}

export type CallToActionUpdated = {
    [Key in CallToActionDisabledMessages]?: string;
};


interface CallToActionUpdate extends CallToAction {
    disabledMessages?: { [Key in CallToActionDisabledMessages]: string };
}

export type CartSummaryCallToActions = { [Key in CallToActionNames]?: CallToActionUpdate };

export interface CartSummary extends TitledModule {
    moduleTitle?: string;
    summaryItems?: CartSummaryItem[];
    total?: CartSummaryItem;
    callToActions?: CartSummaryCallToActions;
    badges?: Badge[];
    paymentMethodsIcons?: Icon[];
    userAgreements?: UserAgreement[];
    messages?: TextualDisplay[];
}

export interface Badge {
    title?: IconAndText;
}

export interface UserAgreement {
    legalText?: string;
    disclosureMessage?: TextualDisplay;
    placement?: 'PRIMARY' | 'SECONDARY';
    termsAndConditions?: TextualDisplay[];
}

export enum MessageType {
    Success = 'SUCCESS',
    Error = 'ERROR',
    Info = 'INFO'
}

export interface Notification extends Message {
    messageType?: MessageType;
    action?: Action;
    message?: TextualDisplay[];
    accessibilitySeverity?: string;
}

export interface Notifications {
    notifications: Notification[];
}

export interface SavedForLater {
    moduleTitle?: string;
    bulkActions: BulkActionsData;
    lineItems?: LineItem[];
}

export interface Survey {
    moduleTitle?: string;
    surveyDetails?: TextualDisplay[];
}

export interface Banner {
    bannerCard?: BannerCard;
    backgroundColor?: string;
}

export type RewardDetails = IconAndText[];
export interface Rewards extends Module {
    rewardDetails?: RewardDetails;
}

export enum AdsPlacementId {
    Desktop = 101519,
    Mobile = 101520,

    // Empty cart placements
    DeskopRecommendation = 102073,
    MobileRecommendation = 102075,
    DesktopWatchlist = 102074,
    MobileWatchlist = 102076
}

export interface AdsData {
    listingIds?: AdListing[];
    placementId?: AdsPlacementId;
}

export interface Modules {
    adsListings?: HtmlModule;
    cartTitle?: CartTitle;
    cartDetails?: CartDetails;
    cartSummary?: CartSummary;
    compactCartSummary?: CartSummary;
    listingHeroData?: ListingIdToHeroDataMap;
    notifications?: Notifications;
    savedForLater?: SavedForLater;
    signals?: Signals;
    survey?: Survey;
    banner?: Banner;
    rewards?: Rewards;
    pricelines?: Pricelines;
    [k: `itemSpecifics_${string}`]: SectionModule;
}

export enum SignalsExperimentTreatments {
    Control = '0',
    TreatmentOne = '1',
    TreatmentTwo = '2',
    TreatmentThree = '3'
}

export interface EnableFGQLSignalsFeatureFlagExperimentResponseObject extends FeatureFlagExperimentResponseObject {
    'web.xoBundledSignals'?: SignalsExperimentTreatments;
}

export interface CartBulkXOFeatureFlagExperimentResponseObject extends FeatureFlagExperimentResponseObject {
    'CART_BULK_XO'?: string;
}

export interface FeatureFlagsData {
    shoppingCartBulkActionsTourTip?: boolean;
    displaySummaryPaymentIcons?: boolean;
    treatmentIds?: string[];
    qtags?: QtagsProcessedData;
    cartBulkXoEnabled?: CartBulkXOFeatureFlagExperimentResponseObject;
    reactEnablePreloadHtml?: boolean;
    dWebOrderSummaryOptimization?: boolean;
    dWebBannerReposition?: boolean;
    preRenderCheckoutFromEdge?: FeatureFlagExperimentResponseObject;
    enableGuestOTPAuthFlow?: FeatureFlagExperimentResponseObject;
    mobileEMBGWithGreyBackgroundSummary?: boolean;
    showEmptyCartMerch?: FeatureFlagExperimentResponseObject;
    enableQuickView?: boolean;
    enableFGQLSignals?: EnableFGQLSignalsFeatureFlagExperimentResponseObject;
    openLinksInNewTab?: boolean;
}

export interface DeviceTypeData {
    deviceType?: string;
}

export interface ClientLocales {
    cartPageTitle?: string;
    genericErrorMessage?: string;
    signInChooserContinueAsGuest?: string;
    signInChooserContinueToSignIn?: string;
    signInChooserCloseDialog?: string;
    signInChooserDialogAriaLabel?: string;
    spinnerAriaLabel?: string;
    bulkActionsTourtipContent?: string;
    bulkActionsTourtipA11YClose?: string;
    bulkCheckoutTourTipContent?: string;
    signalsLocales?: {
        [key: string]: string;
    };
    emptyCartWithWatchlist?: {
        [key: string]: string;
    };
}

export interface QuantumMetrics {
    enabled: boolean;
    samplingRate: number;
    scriptUrl: string;
    integrity: string;
}

export interface CartClientState {
    appMeta?: AppMeta;
    appConfig?: AppConfig;
    appSiteConfig?: AppSiteConfig;
    meta?: Meta;
    modules?: Modules;
    featureFlagsData?: FeatureFlagsData;
    i18n?: ClientLocales;
    sessionToken?: string;
    continueShoppingUrl?: string;
    adsData?: AdsData;
    fgql?: FGQLData;
}

export interface FGQLData {
    signals?: GetSignalsFGQLResponse;
}

export interface LayoutProps {
    initialState?: CartClientState;
    cartState?: CartClientState;
    handleCartStateUpdate?: CartClientState;
}

export interface Signal {
    name: string;
    type: string;
    params?: {
        [key: string]: string;
    };
}

export interface SignalMap {
    [key: string]: Signal[];
}

export interface Signals {
    itemSignals?: SignalMap;
    sellerSignals?: SignalMap;
}

export interface ListingIdToHeroDataMap {
    [key: string]: object;
}


// Dynamic Bulk XO - Item Pricelines types
export interface CurrencyValue {
    value?: number;
    convertedFromValue?: number;
    convertedFromCurrency?: string;
    currency?: string;
}

export interface PricelinesCostSummary {
    itemSubtotalPrice?: CurrencyValue;
    totalAppliedPromotions?: CurrencyValue;
    totalPrice?: CurrencyValue;
}

export interface ProFormaOrderDetails {
    referenceId?: string;
    shopper?: Shopper;
    sellerProFormaOrders?: any;
    pricingDetails?: any;
    costSummary?: PricelinesCostSummary;
}

export interface Pricelines {
    proFormaOrder?: ProFormaOrderDetails;
}

export interface PriceLinesDetails {
    pricelines: Pricelines;
    result?: string;
}

export interface CartSummaryPricelines {
    modules: PriceLinesDetails;
}
