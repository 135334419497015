import classNames from 'classnames';
import { EbayButton } from '@ebay/ui-core-react/ebay-button';
import { EbayTrackingClick } from '@ebay/ebayui-app-react/ebay-tracking';
import { TEST_IDS } from '../../../../common/utils/test-ids';
import { useAppMetaContextData } from '../../../../common/utils/app-meta-context/hooks';
import { triggerSignInOTPAuthFlow } from '../../../../common/utils/auth-modal';
import { getActionTrackingData } from '../../../../common/utils/tracking-utils';
import { useFeatureFlagsData } from '../../../../common/utils/experiments/hooks';
import { EmptyCartActionsProps } from './types';

export function EmptyCartActions({ actions }: EmptyCartActionsProps) {
    const startShopping = actions?.START_SHOPPING;
    const signIn = actions?.SIGN_IN;
    const { appMeta, meta } = useAppMetaContextData();
    const featureFlagsData = useFeatureFlagsData();

    if (!startShopping) {
        return null;
    }

    const clientTrackingData = getActionTrackingData({
        isGuest: appMeta?.isGuest,
        shoppingCartId: meta?.shoppingCartId,
        refActionName: 'EMPTY_CART_SIGN_IN'
    });

    const handleClick = () => {
        const action = signIn?.action;
        if (action?.name === 'SIGN_IN') {
            triggerSignInOTPAuthFlow(false, appMeta, meta?.signInPageUrlObj?.signInPageUrl || action?.URL, featureFlagsData );
        } else if (action?.URL) {
            window.location.href = action.URL;
        }
    };

    return (
        <div className={classNames('actions', {
            'multi-actions': Boolean(signIn)
        })}>
            {signIn && (
                <EbayTrackingClick trackingList={clientTrackingData}>
                    <EbayButton
                        className="sign-in action"
                        fluid
                        priority={signIn.type === 'PRIMARY' ? 'primary' : 'secondary'}
                        data-test-id={TEST_IDS.signIn}
                        onClick={handleClick}>
                        {signIn.text}
                    </EbayButton>
                </EbayTrackingClick>
            )}

            <EbayButton
                className="action start-shop"
                href={startShopping.action?.URL}
                fluid
                priority={startShopping.type === 'PRIMARY' ? 'primary' : 'secondary'}
                data-test-id={TEST_IDS.startShopping}>
                {startShopping.text}
            </EbayButton>
        </div>
    );
}
