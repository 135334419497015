import React from 'react';
import { QuickViewPopupModule } from '../../../types';

const QuickViewPopupLazy = React.lazy(() =>
    import(
        /* webpackChunkName: "quick-view-popup-lazy.desktop" */
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        './quick-view-popup'
    ).then(
        (module: QuickViewPopupModule) => ({ default: module.QuickViewPopup })
    )
);

export { QuickViewPopupLazy };
