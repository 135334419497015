import { GetSignalsFGQLResponse } from '@ebay/payments-graph-client/signals';
import { FGQLActions } from '../../../../../common/utils/hydration';
import { AjaxCallMaker } from '../../../utils/ajax';
import { GetSignalsDataRequest } from '../types';

export async function getSignalsFromFGQL(makeAjaxCall: AjaxCallMaker<GetSignalsDataRequest>, listingIds: string[]): Promise<GetSignalsFGQLResponse | undefined> {
    try {
        const body = { action: FGQLActions.GetSignals, params: { listingIds } };
        const data = await makeAjaxCall('/api/fgql', body, undefined, false);
        return data?.fgql?.signals;
    } catch (e) {
        // We are doing nothing here as errors here should be logged on backend, so duplicate logging
        // isn't required.
        return undefined;
    }
}
