import { useState, useEffect } from 'react';
import { EbayCheckbox } from '@ebay/ui-core-react/ebay-checkbox';
import { EbayTrackingClick } from '@ebay/ebayui-app-react/ebay-tracking';
import { getTrackingList } from '../../../utils/experience-service-types/action';
import { useBulkActionsContext } from '../context';
import { BulkActionTourtipInactiveContent } from '../bulk-actions-tourtip-content';
import { BulkActionsHookData } from '../types';
import { BulkActionsCheckboxProps } from './types';

export function BulkActionsCheckbox({
    bulkActionSelection,
    itemDisabled,
    lineItemId = '',
    labelId,
    lineItemSummaries = [],
    listingSummaryQuantity = 0,
    transactionId = '-1',
    variationId = '-1',
    itemId = '-1',
    firstLineItem,
    displayPrice = 0,
    children,
    seller
}: BulkActionsCheckboxProps) {
    const {
        activeBulkActionsModule,
        onBulkActionCheckboxChange,
        selectAllCheckboxChecked
    }: BulkActionsHookData = useBulkActionsContext();

    const {
        cartBulkXoEnabled,
        setShouldRenderBulkActionsTourtip,
        setTourtipManuallyClosed,
        shoppingCartBulkActionsTourTip,
        shouldRenderBulkActionsTourtip,
    } = useBulkActionsContext();
    const [checked, setChecked] = useState<boolean>(bulkActionSelection?.selected || selectAllCheckboxChecked);

    useEffect(() => {
        if (!bulkActionSelection?.selected) {
            setChecked(selectAllCheckboxChecked);
        }
    }, [selectAllCheckboxChecked]);

    useEffect(() => {
        setChecked(bulkActionSelection?.selected || false);
    }, [bulkActionSelection]);

    if (!activeBulkActionsModule || !lineItemId) return null;

    const handleAction = (
        event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        const target = event?.target as HTMLInputElement;
        const isChecked = target?.checked;
        setChecked(isChecked);
        onBulkActionCheckboxChange(
            event,
            lineItemId,
            activeBulkActionsModule,
            listingSummaryQuantity,
            !!itemDisabled,
            transactionId,
            variationId,
            itemId,
            displayPrice,
            lineItemSummaries,
            seller
        );
    };

    const blukXoTourTip = cartBulkXoEnabled && (shoppingCartBulkActionsTourTip || shouldRenderBulkActionsTourtip);
    const handleOnTourtipCollapse = () => {
        setShouldRenderBulkActionsTourtip(false);
        setTourtipManuallyClosed(true);
    };

    const checkboxProps = {
        checked: (itemDisabled && cartBulkXoEnabled) ? false : checked,
        onChange: handleAction,
        id: labelId,
        disabled: !!itemDisabled
    };

    if (cartBulkXoEnabled && itemDisabled) {
        // Bulk selection for disabled items is disabled during Bulk XO
        checkboxProps.disabled = true;
    } else if (activeBulkActionsModule && !cartBulkXoEnabled) {
        // Bulk selection for disabled items is enabled during Bulk Actions
        checkboxProps.disabled = false;
    }

    return (
        <div className="cart-bulk-actions-checkbox">
            { blukXoTourTip && firstLineItem ? (
                <BulkActionTourtipInactiveContent pointer="bottom-left" onCollapse={handleOnTourtipCollapse} overlayStyle={{ left: 5 }}>
                    <EbayTrackingClick trackingList={getTrackingList(bulkActionSelection?.action)}>
                        <EbayCheckbox
                            {...checkboxProps}
                            size='large'
                        >
                            {children}
                        </EbayCheckbox>
                    </EbayTrackingClick>
                </BulkActionTourtipInactiveContent>
            ) : (
                <EbayTrackingClick trackingList={getTrackingList(bulkActionSelection?.action)}>
                    <EbayCheckbox
                        {...checkboxProps}
                        size='large'
                    >
                        {children}
                    </EbayCheckbox>
                </EbayTrackingClick>
            )
            }
        </div>
    );
}