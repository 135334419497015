import { useEffect, useRef, useState, MouseEvent } from 'react';
import { TEST_IDS } from '../../../../common/utils/test-ids';
import { useAppMetaContextData } from '../../../../common/utils/app-meta-context';
import { getActionTrackingData } from '../../../../common/utils/tracking-utils';
import { trackPulsar } from '../../../../common/utils/tracking';

import { Adjustment } from './adjustment';
import { AdjustmentsProps } from './types';
import { goToAdjustmentUrl } from './domain/service';

import type { Adjustment as AdjustmentType } from '../../../../../common/utils/hydration';

import './styles.less';

export function Adjustments({ adjustments, sellerId }: AdjustmentsProps) {
    const { appMeta, meta } = useAppMetaContextData();

    const isFirstRender = useRef(true);
    const [isAdjustmentsUpdated, setIsAdjustmentsUpdated] = useState(false);

    useEffect(() => {
        if (!isFirstRender.current) {
            setIsAdjustmentsUpdated(true);
        }

        isFirstRender.current = false;

        return () => {
            setIsAdjustmentsUpdated(false);
        };
    }, [adjustments]);

    const handleOnClick = (event: MouseEvent, adjustment: AdjustmentType) => {
        event.preventDefault();
        const clientTrackingData = getActionTrackingData({
            action: adjustment?.action,
            sellerId,
            isGuest: appMeta?.isGuest,
            shoppingCartId: meta?.shoppingCartId,
        });
        trackPulsar(clientTrackingData[0]);
        goToAdjustmentUrl(adjustment);
    };

    if (!adjustments?.length) {
        return null;
    }

    return (
        <div>
            {adjustments.map(((adjustment, index) => {
                if (adjustment.action) {
                    return (
                        <a
                            key={index}
                            className="lineitem-adjustments adjustments-link"
                            href="#"
                            onClick={event => handleOnClick(event, adjustment)}
                            data-test-id={TEST_IDS.moreDeals}>
                            <Adjustment adjustment={adjustment} />
                        </a>
                    );
                }

                const accessibilityProps = isAdjustmentsUpdated ? {
                    'aria-live': 'polite' as const,
                    'role': 'status'
                } : {};

                return (
                    <div key={index} className="lineitem-adjustments" {...accessibilityProps}>
                        <Adjustment adjustment={adjustment} />
                    </div>
                );
            }))}
        </div>
    );
}
