import { TextDisplay } from '../../common/components/text-display';
import { EmptyCartProps } from './types';
import { EmptyCartActions } from './components/empty-cart-actions';

import './styles.less';

export function EmptyCart({ emptyCart }: EmptyCartProps) {
    if (!emptyCart) {
        return null;
    }

    return (
        <div className="empty-cart">
            {emptyCart.emptyCartMessage && (
                <div className="font-title-3" aria-live="polite">
                    <TextDisplay data={emptyCart.emptyCartMessage} />
                </div>
            )}

            {emptyCart.subMessages?.length && (
                <div className="sub-messages">
                    {emptyCart.subMessages.map((subMessage, index) => (
                        <TextDisplay key={index} data={subMessage} />
                    ))}
                </div>
            )}

            <EmptyCartActions actions={emptyCart.actionMap} />
        </div>
    );
}
