import { EbayTrackingClick } from '@ebay/ebayui-app-react/ebay-tracking';
import { useQuickViewContext } from '../../context';
import { QuickViewActionProps } from '../../types';
import { getActionTrackingData } from '../../../../utils/tracking-utils';
import { useAppMetaContextData } from '../../../../utils/app-meta-context';

export function QuickViewAction({
    children,
    listingId,
    ...rest
}: QuickViewActionProps) {
    const { appMeta, meta } = useAppMetaContextData();
    const { setQuickViewHeroData } = useQuickViewContext();
    const trackingData = getActionTrackingData({
        refActionName: 'OPEN_QUICK_VIEW',
        isGuest: appMeta?.isGuest,
        shoppingCartId: meta?.shoppingCartId
    });
    if (!listingId) {
        return (<>{ children }</>);
    }

    return (
        <EbayTrackingClick trackingList={trackingData}>
            <a
                data-track={JSON.stringify(trackingData)}
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    void setQuickViewHeroData(listingId);
                }}
                {...rest}
            >
                {children}
            </a>
        </EbayTrackingClick>
    );
}
