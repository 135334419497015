import { triggerCheckoutOTPAuthFlow } from '../../../../pres/common/utils/auth-modal';
import { AppContext, CartClientState, ScreenFlowDestination } from '../../../../common/utils/hydration';

function handleSignInRedirect(screenFlowDestination: ScreenFlowDestination, signInUrlBase?: string, currentOrigin?: string, appContext?: AppContext): string {
    if (screenFlowDestination?.name === 'SIGN_IN' && signInUrlBase) {
        // 1. The `params` object exists for handling any passthrough to Checkout
        //    a. If there is `GUEST_XO_URL` value, we append `&gcu=1&gchru` which will allow
        //       the user to either 1) sign-in or 2) do guest checkout
        //    b. If there is no `GUEST_XO_URL`, the user must sign-in to perform any further
        //       actions
        // Logic is further explained at:
        // https://docs.google.com/document/d/1HloFeN3Y8hf2-gH9wcUexsjq5dtjUsLKtWehE5xOsYs

        // Manually construct the RU so we don't get any gibberish with the original URL
        const optimizedMemberUrl = screenFlowDestination?.params?.OPTIMIZED_MEMBER_URL;
        let returnUrl = '';
        if (optimizedMemberUrl) {
            returnUrl = optimizedMemberUrl;
        } else {
            returnUrl = currentOrigin || '';
            returnUrl = returnUrl.charAt(returnUrl.length - 1) !== '/' ? `${returnUrl}/` : returnUrl;
            const RETURN_OPERATION = screenFlowDestination?.params?.RETURN_OPERATION;
            if (RETURN_OPERATION === 'PAY_ONLY_THIS_SELLER') {
                const sellerName = screenFlowDestination?.params?.sellerName || '';
                returnUrl += `api/pots?sellerName=${sellerName}`;
            } else if (RETURN_OPERATION === 'CHECK_OUT_CART') {
                returnUrl += 'api/xo';
            }
        }
        const GUEST_XO_URL = screenFlowDestination?.params?.GUEST_XO_URL;
        const signInUrlWithApi = `${signInUrlBase || ''}&ru=${encodeURIComponent(returnUrl)}`;
        if (appContext?.enableGuestOTPAuthFlow) {
            const { locale, isMobile } = appContext;
            triggerCheckoutOTPAuthFlow(signInUrlBase, { isMobile, locale }, returnUrl, GUEST_XO_URL);
            return '';
        } else if (GUEST_XO_URL) {
            const fullGuestXOSignInUrl = `${signInUrlWithApi}&gch=1&gchru=${encodeURIComponent(GUEST_XO_URL)}`;
            return fullGuestXOSignInUrl;
        }
        return signInUrlWithApi;
    }
    return '';
}

export function getScreenFlowDestinationUrl(screenFlowDestination?: ScreenFlowDestination, signInUrlBase?: string, fullSignInUrl?: string, currentOrigin?: string, appContext?: AppContext): string {
    if (screenFlowDestination) {
        const siteSpeedParam = screenFlowDestination?.siteSpeedParam;
        const url = screenFlowDestination?.URL;
        if (url) {
            if (siteSpeedParam?.carttime && url) {
                return `${url}&carttime=${siteSpeedParam.carttime}`;
            }
            return url;
        } else if (screenFlowDestination.params) {
            return handleSignInRedirect(screenFlowDestination, signInUrlBase, currentOrigin, appContext);
        }
    }
    return '';
}

export function shouldShowSignInChooser(cartState?: CartClientState): boolean {
    return cartState?.meta?.screenFlowDestination?.name === 'SHOW_STREAMLINE_BIN';
}

export function hasFatalError(cartState?: CartClientState): boolean {
    return Boolean(!cartState?.modules?.notifications && !cartState?.modules?.cartDetails && !cartState?.modules?.cartSummary);
}
