import { BulkActions } from '../../common/components/bulk-actions';
import { useAjax } from '../../common/utils/ajax';
import { useFeatureFlagsData } from '../../common/utils/experiments/hooks';
import { SpinnerContainer } from '../../common/components/spinner';
import { extractLineItemIdsFromLineItems } from '../../common/components/bulk-actions/utils';
import { makeBulkAction } from '../../common/components/bulk-actions/actions/domain/service';
import { RemoveBulkItemsAction, BulkActionType } from '../../common/components/bulk-actions/actions/types';
import { useFocusOnActionContext } from '../../common/utils/focus-on-action/context';
import { isBulkCheckoutEnabled } from '../../common/components/bulk-actions/utils/utils';
import { SavedForLaterLineItem } from './components/saved-for-later-line-item';
import type { SavedForLaterProps } from './types';
import './styles.less';

export function SavedForLater({ savedForLater, onBulkAction, onAddBackToCart, onRemoveItem }: SavedForLaterProps) {
    const featureFlagData = useFeatureFlagsData();
    const { focusOnSFLContainer } = useFocusOnActionContext();
    const makeAjaxCall = useAjax();
    if (!savedForLater) {
        return null;
    }
    const lineItems = extractLineItemIdsFromLineItems(savedForLater?.lineItems);
    const bulkCheckoutEnabled = isBulkCheckoutEnabled(featureFlagData?.cartBulkXoEnabled);
    const handleBulkAction = (action?: RemoveBulkItemsAction): Promise<void> => {
        switch (action?.actionType) {
            case BulkActionType.Remove:
                return makeBulkAction(makeAjaxCall, action)
                    .then(onBulkAction)
                    .catch((error: Error) => {
                        console.error('[BulkAction] Error in the request', error?.message);
                    });
            default:
                return Promise.resolve();
        }
    };
    return (
        <div className="saved-for-later-container" tabIndex={focusOnSFLContainer ? 0 : -1}>
            <SpinnerContainer>
                {
                    featureFlagData?.shoppingCartBulkActionsTourTip && !bulkCheckoutEnabled ? (
                        <BulkActions
                            data={{ ...savedForLater?.bulkActions }}
                            lineItems={lineItems}
                            moduleName="SAVE_FOR_LATER"
                            onBulkAction={handleBulkAction}
                            title={savedForLater?.moduleTitle}
                        />
                    ) : (
                        <h2 className="saved-for-later-title">{savedForLater.moduleTitle}</h2>
                    )
                }
                <ul className="tiles-container">
                    {
                        savedForLater.lineItems?.length &&
                    savedForLater.lineItems.map((lineItem, index) => (
                        <SavedForLaterLineItem
                            key={index}
                            lineItem={lineItem}
                            onAddBackToCart={onAddBackToCart}
                            onRemoveItem={onRemoveItem}
                        />
                    ))
                    }
                </ul>
            </SpinnerContainer>
        </div>
    );
}
