import { extractText } from '@ebay/ebayui-app-react/ebay-textual-display';
import cx from 'classnames';
import { isGuestOTPAuthFlowEnabled } from '../../../common/utils/auth-modal/util';
import { ContextProvider } from '../context-provider';
import { AdsPlacementId, LayoutProps } from '../../../../common/utils/hydration';
import { useCartState } from '../../../common/utils/state-management';
import { PageTitle } from '../../../modules/page-title';
import { Cart } from '../../../modules/cart';
import { SurveyLink } from '../../../modules/survey-link';
import { Banner } from '../../../modules/banner';
import { SignInChooser } from '../../../modules/signin-chooser';
import { CartSummary } from '../../../modules/cart-summary';
import { SavedForLater } from '../../../modules/saved-for-later';
import { AdsListings } from '../../../modules/ads';
import { useRedirectIfRequiredOnMount, useUpdateGlobalHeaderCartValue } from '../hooks';
import { createCartStateHandler, createSignInChooserData } from '../domain/factories';
import { hasFatalError, shouldShowSignInChooser } from '../domain/specifications';
import { AlertLevel, Alerts, FatalErrorAlerts } from '../../../common/components/alerts';
import { QuickView } from '../../../common/components/quick-view';
import './styles.less';

export function Layout({ initialState }: LayoutProps) {
    const { cartState, setCartState } = useCartState(initialState);
    const appContext = {
        enableGuestOTPAuthFlow: isGuestOTPAuthFlowEnabled(initialState?.featureFlagsData),
        isMobile: initialState?.appMeta?.isMobile,
        locale: initialState?.meta?.locale
    };
    const signInChooserData = createSignInChooserData(cartState, initialState?.meta?.signInPageUrlObj, appContext);

    const handleCartStateUpdate = createCartStateHandler(
        setCartState,
        appContext,
        initialState?.meta?.signInPageUrlObj
    );

    const isRedirectRequired = useRedirectIfRequiredOnMount(cartState, initialState?.appConfig);
    useUpdateGlobalHeaderCartValue(cartState);

    return (
        <ContextProvider
            cartState={cartState}
            initialState={initialState}
            deviceType='desktop'
            isRedirectRequired={isRedirectRequired}
            onBulkSelection={handleCartStateUpdate}
        >
            <div className="app-dsk">
                {hasFatalError(cartState) ? (
                    <div id="page-alerts" tabIndex={-1} role="alert">
                        <FatalErrorAlerts />
                    </div>
                ) : (
                    <>
                        <div className="top-section">
                            <PageTitle
                                title={
                                    cartState?.modules?.cartTitle?.title ?
                                        extractText(cartState?.modules?.cartTitle?.title)
                                        : cartState?.modules?.cartDetails?.moduleTitle
                                }
                            />

                            <SurveyLink survey={cartState?.modules?.survey} />
                        </div>

                        {cartState?.modules?.notifications && (
                            <div id="page-alerts" tabIndex={-1} role="alert">
                                <Alerts notifications={cartState.modules.notifications.notifications} level={AlertLevel.Page} />
                            </div>
                        )}

                        <div className={cx(
                            'columns',
                        )}
                        >
                            <div className={
                                cx(
                                    'left-column',
                                )
                            }>
                                <Banner
                                    banner={cartState?.modules?.banner} />
                                <Cart
                                    cartDetails={cartState?.modules?.cartDetails}
                                    onBulkAction={handleCartStateUpdate}
                                    onBuyItNow={handleCartStateUpdate}
                                    onQuantityUpdate={handleCartStateUpdate}
                                    onPayOnlyThisSeller={handleCartStateUpdate}
                                    onSavedForLater={handleCartStateUpdate}
                                    onRemoveItem={handleCartStateUpdate}
                                />

                                <AdsListings
                                    adsData={cartState?.adsData}
                                    placementId={AdsPlacementId.Desktop} />

                                <SavedForLater
                                    savedForLater={cartState?.modules?.savedForLater}
                                    onBulkAction={handleCartStateUpdate}
                                    onAddBackToCart={handleCartStateUpdate}
                                    onRemoveItem={handleCartStateUpdate}
                                />
                            </div>

                            { !!cartState?.modules?.cartSummary && (
                                <div className={
                                    cx(
                                        'right-column',
                                    )
                                }>
                                    <div className='sticky-wrapper'>
                                        <CartSummary
                                            callToActionBelow
                                            cartSummary={cartState?.modules?.cartSummary}
                                            fixedPosition
                                            onGoToCheckout={handleCartStateUpdate}
                                            fopIcons={cartState?.modules?.cartSummary?.paymentMethodsIcons}
                                            continueShoppingUrl={cartState?.meta?.continueShoppingUrl}
                                            rewards={cartState?.modules?.rewards}
                                            banner={cartState?.modules?.banner}
                                        />
                                        <Banner
                                            banner={cartState?.modules?.banner}
                                            miniBanner />
                                    </div>
                                </div>
                            )}
                        </div>
                        <SignInChooser
                            open={shouldShowSignInChooser(cartState)}
                            pageId={initialState?.appMeta?.pageId}
                            data={signInChooserData}
                        />
                        <QuickView />
                    </>
                )}
            </div>
        </ContextProvider>
    );
}
