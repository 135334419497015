import { Suspense } from 'react';
import { useQuickViewContext } from '../../context';
import { QuickViewPopupLazy } from '../quick-view-popup-lazy';
import './quick-view.less';

export function QuickView() {
    const {
        shouldShowQuickViewPopup,
        getQuickViewHeroData,
        getIsQuickViewOpen,
        closeQuickView
    } = useQuickViewContext();
    if (!shouldShowQuickViewPopup()) {
        return null;
    }

    // The Hero Component shows a silhouette when heroData is empty, so we need to show
    // the dialog / lightbox while hero data is being fetched.
    const heroData = getQuickViewHeroData();
    const isQuickViewOpen = getIsQuickViewOpen();
    return (
        <Suspense>
            <QuickViewPopupLazy
                heroData={heroData}
                isOpen={isQuickViewOpen}
                onClose={closeQuickView}
            />
        </Suspense>
    );
}
