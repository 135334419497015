// eslint-disable-next-line import/no-extraneous-dependencies
import { NumberFormat } from '@ebay/intl-ebay';
import { ListingSummary } from '@ebay/experience-types-ebay';
import { extractText } from '@ebay/ebayui-app-react/ebay-textual-display';
import {
    BulkActionItemsMap,
    BulkActionsActiveState,
    BulkActionsDataMap,
    BulkActionsHookData,
    BulkActionsModules,
    BulkActionsSelectedItems,
    BulkItemQtyAndPriceDetails,
    ProFormaOrderDetails
} from '../types';
import { SellerBucket, LineItem, ShoppingCartListingSummary } from '../../../../../common/utils/hydration';
import { TextualDisplayWithInfoBubble, Seller, CartSummaryItem, CartBulkXOFeatureFlagExperimentResponseObject } from '../../../../../common/utils/hydration/types';
import { BulkSelectRequestPayload, BulkSelectRequest } from '../types';

export function buildBulkActionButtonTextBasedOnQuantity(text = '', buttonQuantitySelected: number) {
    const selectedItemsText = buttonQuantitySelected ? `(${buttonQuantitySelected})` : '';
    return [text, selectedItemsText].filter(Boolean).join(' ');
}

export function buildBulkActionButtonText(text = '', bulkActionItemsMap: BulkActionItemsMap) {
    const selectedItemsText = bulkActionItemsMap.size ? `(${bulkActionItemsMap.size})` : '';
    return [text, selectedItemsText].filter(Boolean).join(' ');
}

export function getActiveState(bulkActionsModule: BulkActionsModules, moduleName: BulkActionsModules): BulkActionsActiveState {
    if (bulkActionsModule === null) {
        return BulkActionsActiveState.Initial;
    }
    if (bulkActionsModule === moduleName) {
        return BulkActionsActiveState.Active;
    }
    return BulkActionsActiveState.Disabled;
}

export const transformMapToArrayOfLineItemIds = (bulkActionItemsMap: BulkActionItemsMap): BulkActionsSelectedItems => {
    return Array.from(bulkActionItemsMap.values());
};

export const isDisabledButton = (
    activeState: string = BulkActionsActiveState.Disabled,
    moduleName: BulkActionsModules,
    activeBulkActionsModule: BulkActionsModules
): boolean => {
    return activeState !== BulkActionsActiveState.Initial && moduleName !== activeBulkActionsModule;
};

export function isLineItemDisabled(lineItem: LineItem | undefined): boolean {
    return !!lineItem?.listingSummaries?.[0]?.__sc?.disabled;
}

export function isListingSummaryDisabled(listingSummary: ShoppingCartListingSummary[] = []): boolean {
    return !!listingSummary?.[0]?.__sc?.disabled;
}

export const extractLineItemsFromSellerBuckets = (sellerBuckets: SellerBucket[] = []): LineItem[] => {
    const lineItemsOutput = [];
    for (const sellerBucket of sellerBuckets) {
        const lineItems = sellerBucket?.lineItems || [];
        for (const lineItem of lineItems) {
            if (lineItem.bulkActionSelection) {
                lineItemsOutput.push({ lineItemId: lineItem?.lineItemId });
            }
        }
    }
    return lineItemsOutput;
};

export const extractLineItemIdsFromLineItems = (lineItems: LineItem[] = []): LineItem[] => {
    const lineItemsIds = [];
    for (const lineItem of lineItems) {
        if (lineItem.bulkActionSelection) {
            lineItemsIds.push({ lineItemId: lineItem?.lineItemId });
        }
    }
    return lineItemsIds;
};

export const shouldNotRenderLineItemActions = (bulkActionsContext: BulkActionsHookData): boolean => {
    if (bulkActionsContext.cartBulkXoEnabled) {
        return false;
    }
    return bulkActionsContext.activeBulkActionsModule !== null;
};

export const extractQuantityFromListingSummary = (listingSummary: ShoppingCartListingSummary): number => {
    if (listingSummary?.quantity) {
        return Number(listingSummary?.quantity?.value || 0);
    }
    if (listingSummary?.__sc?.quantityForm?.selectionForm) {
        return Number(listingSummary?.__sc?.quantityForm?.selectionForm?.selected?.value || 0);
    }
    if (listingSummary?.__sc?.quantityForm?.entryForm) {
        return Number(listingSummary?.__sc?.quantityForm?.entryForm?.group?.[0]?.paramValue || 0);
    }
    return 0;
};

export const getTransactionIdFromListingSummary = (listingSummary: ShoppingCartListingSummary): string => {
    return listingSummary?.__sc?.transactionId || '-1';
};

export const getVariationIdFromListingSummary = (listingSummary: ShoppingCartListingSummary): string => {
    return listingSummary?.variationId || '-1';
};

export const getItemIdFromListingSummary = (listingSummary: ShoppingCartListingSummary): string => {
    return listingSummary?.listingId || '-1';
};

export const getDisplayPriceFromListingSummary = (listingSummary: ListingSummary): number => {
    if (listingSummary?.displayPrice?.value) {
        return Number(listingSummary?.displayPrice?.value?.value || 0);
    }
    return 0;
};

export const getBulkXoItemsMapEntry = (lineItem: LineItem): BulkActionsDataMap => {
    return {
        lineItemSummaries: lineItem.listingSummaries
    };
};

export const buildSellerObject = (seller?: Seller): Seller => {
    const sellerName = extractText(seller?.sellerName);
    const sellerId = seller?.sellerIdentifier || '';

    return {
        userId: String(sellerId) || '',
        userLoginName: sellerName || ''
    };
};

export const extractLineItemsDetailsFromSellerbuckets = (sellerBuckets?: SellerBucket[], existingKeys: string[] = []): BulkItemQtyAndPriceDetails => {
    if (!sellerBuckets || !Array.isArray(sellerBuckets) || !sellerBuckets?.length) return {};
    let outputQuantity = 0;
    let bulkXoQuantity = 0;
    let bulkXoDisplayPrice = 0;
    const bulkActionItemsMap = new Map();
    const allItemsMap = new Map();

    for (const sellerBucket of sellerBuckets) {
        const { lineItems = [], seller = {} } = sellerBucket;
        for (const lineItem of lineItems) {
            const { listingSummaries = [] } = lineItem;
            if (!lineItem?.bulkActionSelection) {
                continue;
            }
            for (const listingSummary of listingSummaries) {
                const newQuantity = extractQuantityFromListingSummary(listingSummary);
                outputQuantity += newQuantity;

                if (lineItem?.lineItemId && bulkActionItemsMap) {
                    if (existingKeys.length === 0 || existingKeys.includes(String(lineItem?.lineItemId))) {
                        const itemId = getItemIdFromListingSummary(listingSummary);
                        const listingSummaryQuantity = extractQuantityFromListingSummary(listingSummary);
                        const transactionId = getTransactionIdFromListingSummary(listingSummary);
                        const variationId = getVariationIdFromListingSummary(listingSummary);


                        const itemProperties = {
                            lineItemId: lineItem?.lineItemId,
                            itemId,
                            seller: buildSellerObject(seller),
                            transactionId: transactionId,
                            quantity: listingSummaryQuantity,
                            variationId: variationId,
                            ...getBulkXoItemsMapEntry(lineItem)
                        };
                        allItemsMap.set(lineItem?.lineItemId, itemProperties);

                        if (lineItem?.bulkActionSelection?.selected) {
                            bulkActionItemsMap.set(lineItem?.lineItemId, itemProperties);
                            bulkXoDisplayPrice += Number(getDisplayPriceFromListingSummary(listingSummary));
                            bulkXoQuantity += newQuantity;
                        }
                    }
                }

                if (isLineItemDisabled(lineItem) && newQuantity === 0) {
                    outputQuantity += 1;
                }
            }
        }
    }

    return {
        bulkActionItemsMap,
        bulkXoDisplayPrice,
        bulkXoQuantity,
        quantityTotal: outputQuantity,
        allItemsMap
    };
};

export const formatBulkXoTotal = (value: number, locale = 'en-US', currency = ''): string => {
    const format = new NumberFormat(locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    }).format(value);

    if (currency) {
        if (/[a-zA-Z]$/.test(currency)) {
            // Note: Currencies in EU have alphabetical currency symbols where we need to add a space after the currency symbol
            return `${currency} ${format}`;
        }
        return `${currency}${format}`;
    }
    return format;
};

export const calculateBulkXoTotal = (bulkActionsMap: BulkActionItemsMap): number => {
    const itemsMap = Array.from(bulkActionsMap.values());
    const itemTotal = itemsMap.reduce((acc, currentValue) => Number(acc) + Number(currentValue?.displayPrice), 0);
    return itemTotal;
};

export const getCurrencyPrefix = (value?: TextualDisplayWithInfoBubble): string => {
    // For Bulk XO, we extract currency symbol from service response for formatting amount on front-end
    // This method takes the textualDisplay as input and parses the currency symbol
    try {
        let textString = value?.textSpans?.['0']?.text || '';
        textString = textString.replace(/[0-9,.]/g, '');
        return textString.toString().trim();
    } catch (e) {
        return '';
    }
};

export const convertBulkActionsMapToObject = (inputMap: BulkActionItemsMap) => {
    const itemMapObj: { [key: string]: any } = {};
    inputMap.forEach((value, key) => {
        itemMapObj[key] = value;
    });

    return itemMapObj;
};

export const buildCartSummaryDiscountPricelines = (proForma?: ProFormaOrderDetails, locale = 'en-US', currencyPrefix = ''): Record<string, string> => {
    if (proForma) {
        const { totalAppliedPromotions } = proForma?.costSummary || {};
        return {
            totalAppliedPromotions: formatBulkXoTotal(totalAppliedPromotions?.value || 0, locale, currencyPrefix),
        };
    }
    return {};
};

export const buildCartSummaryPricelines = (summaryItems?: CartSummaryItem[], total?: CartSummaryItem): Record<string, string> => {
    const cartSummaryPricelines: Record<string, string> = {};
    if (summaryItems) {
        summaryItems?.forEach((item) => {
            if (item?.type === 'ITEM_TOTAL') {
                cartSummaryPricelines['itemSubtotalPrice'] = extractText(item?.value) || '';
            }

            if (item?.type === 'SHIPPING') {
                cartSummaryPricelines['shippingPrice'] = extractText(item?.value) || '';
            }
        });
    }

    cartSummaryPricelines['totalPrice'] = extractText(total?.value) || '';
    return cartSummaryPricelines;
};

export const buildSelectionsRequestPayload = (inputMap: BulkActionItemsMap, allItemsMap: BulkActionItemsMap, selectAll = false): BulkSelectRequest => {
    const selections: BulkSelectRequestPayload[] = [];
    allItemsMap.forEach((value) => {
        const lineItemId = value?.lineItemId;
        selections.push({
            lineItemId,
            selected: inputMap.has(lineItemId || '') || false
        });
    });

    if (selectAll) {
        return {
            selectAll: true
        };
    }
    return {
        selections
    };
};

export const isBulkCheckoutEnabled = (data?: CartBulkXOFeatureFlagExperimentResponseObject): boolean => {
    return data?.['CART_BULK_XO'] === 'true';
};
