import { TrackingData, trackPulsar } from './tracking';
import { MEMBER_CART_PAGE_ID, GUEST_CART_PAGE_ID, DEFAULT_CLICK_TRACKING_DATA, MODULE_TRACKING_DATA } from './tracking/constants';
import { ImpressionTrackingData, ActionTrackingData } from './tracking/types';

export function getCartPageId(isGuest?: boolean): number {
    return isGuest ? GUEST_CART_PAGE_ID : MEMBER_CART_PAGE_ID;
}

export function getActionTrackingData({ action, isGuest, shoppingCartId, refActionName, sellerId, itemReferenceId, itemId }: ActionTrackingData): TrackingData[] {
    const actionName = refActionName && Object.prototype.hasOwnProperty.call(MODULE_TRACKING_DATA, refActionName)
        ? refActionName
        : action?.name;
    if (!actionName) {
        return [];
    }
    const cartPageId = getCartPageId(isGuest);
    const sid = getSID(String(cartPageId), actionName);
    const trackingData = {
        ...DEFAULT_CLICK_TRACKING_DATA,
        operationId: String(cartPageId),
        eventProperty: {
            sid,
            itemId,
            itemReferenceId,
            actionKind: 'CLICK', // As per tracking team suggestion, actionKind is part of eventproperty.
            pge: cartPageId,
            ebc: shoppingCartId,
            sellerId: sellerId
        }
    };
    return [trackingData];
}

export function trackPageImpressions({ ebc, isGuest }: ImpressionTrackingData) {
    const cartPageId = getCartPageId(isGuest);
    const genericTrackingData = {
        eventFamily: 'CART',
        eventAction: 'CLIENT_PAGE_VIEW',
        clientImpressionPageId: String(cartPageId),
        operationId: String(cartPageId),
        eventProperty: {
            imp: cartPageId,
            pge: cartPageId,
            ebc: ebc
        }
    };
    trackPulsar(genericTrackingData);
}

export function getSID(pageId: string, actionName?: string) {
    if (!actionName) {
        return `p${pageId}`;
    }
    const moduleData = MODULE_TRACKING_DATA[actionName];

    if (moduleData) {
        return `p${pageId}.m${moduleData?.mid}.l${moduleData?.lid}`;
    }
    return null;
}

