import { ComponentType, ReactNode } from 'react';
import { CartClientState } from '../../../../common/utils/hydration';

export type HeroData = object;

export interface QuickViewPopupProps {
    heroData: HeroData;
    isOpen: boolean;
    onClose: () => void;
}

export interface QuickViewActionProps {
    listingId?: string;
    children: ReactNode;
}

export interface QuickViewContextProviderProps {
    children?: ReactNode;
    cartState?: CartClientState;
}

export interface QuickViewHookData {
    closeQuickView: () => void;
    getIsQuickViewOpen: () => boolean;
    getQuickViewHeroData: () => HeroData | null;
    setQuickViewHeroData: (listingId?: string) => Promise<void>;
    openQuickView: () => void;
    setShouldShowQuickViewAction: () => void;
    shouldShowQuickViewAction: () => boolean;
    shouldShowQuickViewPopup: () => boolean;
}

export interface QuickViewPopupModule {
    QuickViewPopup: ComponentType<QuickViewPopupProps>;
}

export interface ListingHeroDataRequest {
    listingIds: string[];
}

export enum QuickViewLoadingState {
    NotStarted = 'NOT_STARTED',
    PageLoaded = 'PAGE_LOADED',
    Done = 'DONE'
}
